<template>
  <div class="wrap">
   <Loading v-show="isLoading"/>
   <div v-show="!isLoading" class="wrap1">
    <van-row>
      <van-col span="22" offset="1" class="fieldTop">
        <van-cell title="付款状态" :value="payMethod=='2'?'确认已付款':'已支付'" value-class="redClass" />
        <van-cell title="付款申请编号" :value="payInfo.id || '--'" />
      </van-col>
      <van-col span="22" offset="1" class="fieldTop">
        <van-cell title="网银流水号" :value="payInfo.bankNo || '--'" />
        <van-cell title="付款账户名称" :value="payInfo.accountNo || '--'" />
      </van-col>
      <van-col class="top-title">合同列表</van-col>
      <van-col class="contractCol" span="22" offset="1" v-for="(item,index) in list" :key="index" style="margin-top:10px;border-radius:5px;">
        <van-cell :center="true">
          <template #title></template>
          <template #label>
            <div class="cell-label">
              <span class="custom-label">申请编码：</span>
              <span class="custom-value">{{item.tradeCode || '--'}}</span>
            </div>
            <div class="cell-label">
              <span class="custom-label">前置保证金：</span>
              <span class="custom-value">{{ item.totalRent || '--' }}元</span>
              <span style="float:right;font-size: 12px;color: #666;" @click="handdleShowPopup(index)">查看详情<van-icon name="arrow" style="font-size: 14px;position: relative;top: 2px;margin-left: 4px;" /></span>
            </div>
          </template>
        </van-cell>
      </van-col>
    </van-row>
    <van-popup
      v-model="showPopup"
      closeable
      close-icon-position="top-left"
      position="bottom"
      round
      :style="{ height: '70%' }"
    >
      <div class="tipTitile">合同信息</div>
      <van-cell-group class="popupCont">
        <van-cell title="客户姓名" :value="popupData.customerName || '--'" />
        <van-cell title="申请编号" :value="popupData.tradeCode || '--'" />
        <van-cell title="合同编号" :value="popupData.contractCode || '--'" />
        <van-cell title="产品名称" :value="popupData.productName || '--'" />
        <van-cell title="是否挂靠" :value="popupData.isAffiliate=='1' ? '是' :'否'" />
        <van-cell title="前置保证金" :value="popupData.totalRent || '--'" />
      </van-cell-group>
    </van-popup>
    <div class="btnBottom" v-if="isShow=='1'">
        <van-col span="20" offset="2">
          <van-button round plain block color="#3662EC" @click="handdleSubmit">
          修正付款信息
          </van-button>
        </van-col>
      </div>
    </div>
  </div>
    
</template>
<script>
  import {getOpenId,getRequestCorpPayContractInfo} from '@/server'
  import { Toast } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,hidewxOption} from '@/lib/utils';
  export default {
    name: 'paymentDetail',
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      isEmpty:false,
      emptyImg:require('@/assets/img/empty.png'),
      showPopup:false,
      popupData:{},
      list:[],
      payInfo:{},
      isLoading:true,
      isShow:'0', //0 隐藏 1 显示
      id:this.$route.query.applyId,
      payMethod:this.$route.query.payMethod
    };
  },
  mounted(){
  },
  created(){
    //隐藏分享
    hidewxOption()
    this.getDetail();
  },
  methods: {
    getDetail(){
      const params = {
        id:this.id,
        origin: "1"
      }
      getRequestCorpPayContractInfo({data:params}).then(res=>{
        const { status,data } = res;
        if(status == '200'){
          this.isLoading = false;
          this.list = data.contractList;
          this.payInfo = data.payInfo;
          this.isShow = data.isShow;
          // this.$router.push({path: '/advanceDeposit/payment'});
        }else{
          this.isLoading = false;
          Toast.fail({
            message: res.subMsg || res.msg,
            duration:'3000'
          });
        }
      })
    },
    handdleShowPopup(index){
      this.popupData = this.list[index];
      this.showPopup = true;
    },
    handdleSubmit(){
      sessionStorage.setItem("origin",'1');
      this.$router.push({path: '/enterprise/enterpriseAdvanceDeposit/payment',query:{id:this.id,flagSouce:'modify'}});
    }
  },
};
</script>
<style>
  .popupCont .van-cell{
    font-size: 15px !important;
  }
</style>>
<style scoped lang="less">
  .wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8;
    .wrap1{
      min-height: 100vh;
      // padding-bottom: 20px;
      .contractCol:last-child{
        padding-bottom: 120px;
      }
    }
    .fieldTop{
      background-color: #fff;
      margin-top: 10px;
      .van-cell__value{
        flex: 2;
      }
      .redClass{
        color: #E50113;
        font-weight: bold;
      }
    }
    .top-title{
      color: #666;
      font-size: 12px;
      padding-left: 25px;
      padding-top: 12px;
    }
    .top-red{
      color: #E50113;
      font-size: 12px;
      padding:12px 0px 15px 15px;
    }
    .tabs{
      padding: 15px 10px;
      height: 40px;
      line-height: 40px;
      // width: 100%;
      background-color: #fff;
      .tab-wrap{
        background-color: #ECECEC;
        display: flex;
        
        .tab{
          flex: 1;
          display: inline-block;
          text-align: center;
          margin: 5px;
          color: #888;
          cursor: pointer;
        }
        .tabActive{
          // margin: 5px;
          background-color: #fff;
          border-radius: 5px;
          color: #333;
        }
      }
      
    }
  }
  .btnBottom{
    position: fixed;
    width: 100%;
    background-color: #fff;
    bottom: 0px;
    padding-bottom: 15px;
    padding-top: 15px;
    .textBootom{
      margin: 10px 0px 20px 20px;
      span{
        display: inline-block;
      }
    }
  }
  .tipTitile{
    color: #1A1A1A;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #eee;
    height: 60px;
    line-height: 60px;
    /* margin-top: 10px; */
  }
  .cell-label{
    margin: 10px 0px; 
  }
  .custom-title{
    font-size:12px;
    color:#666;
  }
  .custom-label{
    color:#333333;
    font-size: 13px;
    font-weight: bold;
  }
  .custom-value{
    font-size: 13px;
    color:#333333;
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  } 
</style>